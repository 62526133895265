import { IInvoiceModel } from "../../../InvoiceModel";
import { CompanyDetails } from "../../_shared/CompanyDetails";
import { BankDetails } from "../../_shared/BankDetails";
import { ClientDetails } from "../../_shared/ClientDetails"

export const ABIC1010: IInvoiceModel = {
  invoiceNumber: "ABIC-1010",
  invoiceDate: "25th April 2024",
  companyDetails: CompanyDetails.BangaloreOffice_IconLaurels,
  billingPeriod: {
    from: "1st Mar 2024",
    to: "31st Mar 2024"
  },
  billTo: ClientDetails.DiecutsMart,
  billingDetails: [
    {
      description: "Phase I - Website setup & development charges",
      sacCode: "998314",
      quantity: 32,
      quantityUnit: "Hour(s)",
      unitPrice: 700
    },
    {
      description: "Phase I - Banner design & content feeding/writing charges",
      sacCode: "998314",
      quantity: 10,
      quantityUnit: "Hour(s)",
      unitPrice: 500
    },
    {
      description: "Phase I - Initial shopify subscription charges",
      sacCode: "998314",
      quantity: 1,
      quantityUnit: "Month(s)",
      unitPrice: 1500
    },
  ],
  tax: {
    cgst: 9,
    sgst: 9
  },
  bankDetails: BankDetails.Primary
}
