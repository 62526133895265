import { IInvoiceModel } from "../../../InvoiceModel";
import { CompanyDetails } from "../../_shared/CompanyDetails";
import { BankDetails } from "../../_shared/BankDetails";
import { ClientDetails } from "../../_shared/ClientDetails";

export const ABIC1030: IInvoiceModel = {
  invoiceNumber: "ABIC-1030",
  invoiceDate: "5th Nov 2024",
  companyDetails: CompanyDetails.BangaloreOffice_IconLaurels,
  billingPeriod: {
    from: "1st Oct 2024",
    to: "31st Oct 2024"
  },
  billTo: ClientDetails.DiecutsMart,
  billingDetails: [
    {
      description: "Monthly Maintenance Charge",
      sacCode: "998314",
      quantity: 1,
      quantityUnit: "Month",
      unitPrice: 3000
    },
    {
      description: "Meta WhatsApp Campaign Setup & Execution",
      sacCode: "998314",
      quantity: 1,
      quantityUnit: "Month",
      unitPrice: 6000
    }
  ],
  tax: {
    cgst: 9,
    sgst: 9
  },
  bankDetails: BankDetails.Primary
}
