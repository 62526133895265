import { IClient } from "../../InvoiceModel";

export class ClientDetails {
  static AccionTechnologies: IClient = {
    companyName: "Accion Technologies Private Limited",
    addressLine1: "6th Floor, D 612, Sigma Soft Tech Park",
    addressLine2: "Whitefield Main Rd, Ramagondanahalli Village",
    city: "Bengaluru (Bangalore) Urban",
    state: "Karnataka",
    pinCode: 560066,
    contactNumber: "",
    emailId: "ap_india@accionlabs.com",
    panNumber: "AADCR5167R",
    gstInNumber: "29AADCR5167R1ZH"
  }
  static AccionNewOffice: IClient = {
    companyName: "Accion Technologies Private Limited",
    addressLine1: "Salarpuria Sattva Knowledge Court, 1, Wing \"B\", 1st Floor, Plot number 9",
    addressLine2: "Phase 1, Doddenkundi industrial area , K.R. Puram Hobli",
    city: "Bangalore",
    state: "Karnataka",
    pinCode: 560048,
    contactNumber: "",
    emailId: "ap_india@accionlabs.com",
    panNumber: "AADCR5167R",
    gstInNumber: "29AADCR5167R1ZH"
  }
  static PQubeBusinessSolutions: IClient = {
    companyName: "PQube Business Solutions Pvt. Ltd",
    addressLine1: "#315, 7th Main, BTM Layout 2nd Stage",
    city: "Bengaluru",
    state: "Karnataka",
    pinCode: 560076,
    contactNumber: "+91-9731249009",
    emailId: "info@pqube.in",
    panNumber: "AACCL4689B",
    gstInNumber: "29AACCL4689B1ZC"
  }
  static QuessCorpLimited: IClient = {
    companyName: "Quess Corp Limited",
    addressLine1: "3/3/2 Bellandur Gate, Sarjapur Road",
    city: "Bengaluru",
    state: "Karnataka",
    pinCode: 560103,
    contactNumber: "1800-572-3333",
    emailId: "help@quesscorp.com",
    panNumber: "AABCI7601M",
    gstInNumber: "29AABCI7601M1ZB"
  }
  static DiecutsMart: IClient = {
    companyName: "Mehnaz Panikkaveettil Azeez",
    addressLine1: "Diecuts Mart, Panikkaveettil House",
    addressLine2: "Vadanapally, Chilanka, Chavakkad",
    city: "Thrissur",
    state: "Kerala",
    pinCode: 680614,
    contactNumber: "+91-9567981843",
    emailId: "mehnazpa@gmail.com",
    panNumber: "FLOPA1359C",
    gstInNumber: "32FLOPA1359C1ZI"
  }
}
