import { IInvoiceMappingModel } from "../../../InvoiceModel";
import { ABIC1001 } from "./ABIC-1001";
import { ABIC1002 } from "./ABIC-1002";
import { ABIC1003 } from "./ABIC-1003";
import { ABIC1004 } from "./ABIC-1004";
import { ABIC1006 } from "./ABIC-1006";
import { ABIC1007 } from "./ABIC-1007";
import { ABIC1008 } from "./ABIC-1008";
import { ABIC1005 } from "./ABIC-1005";

export const mapping_23_24: IInvoiceMappingModel[] = [
  { urlId: "ABIC-1001-1701111376044", invoice: ABIC1001 },
  { urlId: "ABIC-1002-1701176043803", invoice: ABIC1002 },
  { urlId: "ABIC-1003-1702027039", invoice: ABIC1003 },
  { urlId: "ABIC-1004-1704096856", invoice: ABIC1004 },
  { urlId: "ABIC-1005-1704303234", invoice: ABIC1005 },
  { urlId: "ABIC-1006-1707119946", invoice: ABIC1006 },
  { urlId: "ABIC-1007-1709619840", invoice: ABIC1007 },
  { urlId: "ABIC-1008-1711034088", invoice: ABIC1008 }
]
