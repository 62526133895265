import { IInvoiceModel } from "../../../InvoiceModel";
import { CompanyDetails } from "../../_shared/CompanyDetails";
import { BankDetails } from "../../_shared/BankDetails";
import { ClientDetails } from "../../_shared/ClientDetails";

export const ABIC1026: IInvoiceModel = {
  invoiceNumber: "ABIC-1026",
  invoiceDate: "4th Oct 2024",
  companyDetails: CompanyDetails.BangaloreOffice_IconLaurels,
  billingPeriod: {
    from: "1st Sept 2024",
    to: "30th Sept 2024"
  },
  billTo: ClientDetails.AccionNewOffice,
  billingDetails: [
    {
      description: "Consultant Charge for Beno Philip Mathew",
      sacCode: "998313",
      quantity: 152,
      quantityUnit: "Hour(s)",
      unitPrice: 1000
    }
  ],
  tax: {
    cgst: 9,
    sgst: 9
  },
  bankDetails: BankDetails.Primary
}
