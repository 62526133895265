import { IInvoiceModel } from "../../../InvoiceModel";
import { CompanyDetails } from "../../_shared/CompanyDetails";
import { BankDetails } from "../../_shared/BankDetails";
import { ClientDetails } from "../../_shared/ClientDetails";

export const ABIC1021: IInvoiceModel = {
  invoiceNumber: "ABIC-1021",
  invoiceDate: "3rd Aug 2024",
  companyDetails: CompanyDetails.BangaloreOffice_IconLaurels,
  billingPeriod: {
    from: "1st Jul 2024",
    to: "31st Jul 2024"
  },
  billTo: ClientDetails.PQubeBusinessSolutions,
  billingDetails: [
    {
      description: "Consultant Charge (Beno) - ICB Scrum/Meetings/Mentoring",
      sacCode: "998313",
      quantity: 18.5,
      quantityUnit: "Hour(s)",
      unitPrice: 1000
    },
    {
      description: "Consultant Charge (Beno) - ICB Development",
      sacCode: "998313",
      quantity: 15,
      quantityUnit: "Hour(s)",
      unitPrice: 1000
    }
  ],
  tax: {
    cgst: 9,
    sgst: 9
  },
  bankDetails: BankDetails.Primary
}
