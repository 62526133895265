import { IInvoiceModel } from "./InvoiceModel";
import { invoiceMapping } from "./_static/InvoiceMapping";

export class InvoiceService {
  public static GetMappedInvoice(urlId: string): IInvoiceModel | null {
    const mappingItem = invoiceMapping.filter(q => q.urlId === urlId);
    if(mappingItem == null || mappingItem.length <= 0) {
      return null;
    }
    return mappingItem[0].invoice;
  }
}
