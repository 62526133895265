import { IInvoiceMappingModel } from "../../../InvoiceModel";
import { ABIC1009 } from "./ABIC-1009";
import { ABIC1010 } from "./ABIC-1010";
import { ABIC1011 } from "./ABIC-1011";
import { ABIC1012 } from "./ABIC-1012";
import { ABIC1013 } from "./ABIC-1013";
import { ABIC1014 } from "./ABIC-1014";
import { ABIC1015 } from "./ABIC-1015";
import { ABIC1016 } from "./ABIC-1016";
import { ABIC1017 } from "./ABIC-1017";
import { ABIC1018 } from "./ABIC-1018";
import { ABIC1019 } from "./ABIC-1019";
import { ABIC1020 } from "./ABIC-1020";
import { ABIC1021 } from "./ABIC-1021";
import { ABIC1022 } from "./ABIC-1022";
import { ABIC1023 } from "./ABIC-1023";
import { ABIC1024 } from "./ABIC-1024";
import { ABIC1025 } from "./ABIC-1025";
import { ABIC1026 } from "./ABIC-1026";
import { ABIC1027 } from "./ABIC-1027";
import { ABIC1028 } from "./ABIC-1028";
import { ABIC1029 } from "./ABIC-1029";
import { ABIC1030 } from "./ABIC-1030";
import { ABIC1031 } from "./ABIC-1031";
import { ABIC1032 } from "./ABIC-1032";

export const mapping_24_25: IInvoiceMappingModel[] = [
  { urlId: "ABIC-1009-1711974211", invoice: ABIC1009 },
  { urlId: "ABIC-1010-1712487469", invoice: ABIC1010 },
  { urlId: "ABIC-1011-1714543854", invoice: ABIC1011 },
  { urlId: "ABIC-1012-1714543863", invoice: ABIC1012 },
  { urlId: "ABIC-1013-1714544754", invoice: ABIC1013 },
  { urlId: "ABIC-1014-1717081553", invoice: ABIC1014 },
  { urlId: "ABIC-1015-1717081602", invoice: ABIC1015 },
  { urlId: "ABIC-1016-1717081635", invoice: ABIC1016 },
  { urlId: "ABIC-1017-1719733593", invoice: ABIC1017 },
  { urlId: "ABIC-1018-1719733600", invoice: ABIC1018 },
  { urlId: "ABIC-1019-1719733611", invoice: ABIC1019 },
  { urlId: "ABIC-1020-1722699178", invoice: ABIC1020 },
  { urlId: "ABIC-1021-1722699191", invoice: ABIC1021 },
  { urlId: "ABIC-1022-1722699201", invoice: ABIC1022 },
  { urlId: "ABIC-1023-1725370704", invoice: ABIC1023 },
  { urlId: "ABIC-1024-1725370718", invoice: ABIC1024 },
  { urlId: "ABIC-1025-1725370726", invoice: ABIC1025 },
  { urlId: "ABIC-1026-1727981196", invoice: ABIC1026 },
  { urlId: "ABIC-1027-1727981209", invoice: ABIC1027 },
  { urlId: "ABIC-1028-1727981214", invoice: ABIC1028 },
  { urlId: "ABIC-1029-1730451666", invoice: ABIC1029 },
  { urlId: "ABIC-1030-1730451677", invoice: ABIC1030 },
  { urlId: "ABIC-1031-1733205465", invoice: ABIC1031 },
  { urlId: "ABIC-1032-1733205474", invoice: ABIC1032 }
]
