import { IInvoiceModel } from "../../../InvoiceModel";
import { CompanyDetails } from "../../_shared/CompanyDetails";
import { BankDetails } from "../../_shared/BankDetails";
import { ClientDetails } from "../../_shared/ClientDetails";

export const ABIC1013: IInvoiceModel = {
  invoiceNumber: "ABIC-1013",
  invoiceDate: "9th May 2024",
  companyDetails: CompanyDetails.BangaloreOffice_IconLaurels,
  billingPeriod: {
    from: "1st Apr 2024",
    to: "30th Apr 2024"
  },
  billTo: ClientDetails.DiecutsMart,
  billingDetails: [
    {
      description: "Content feeding/writing training & calling support",
      sacCode: "998314",
      quantity: 4,
      quantityUnit: "Hour(s)",
      unitPrice: 500
    },
    {
      description: "Go live preparation, payment gateway integration & domain mapping development cost",
      sacCode: "998314",
      quantity: 8,
      quantityUnit: "Hour(s)",
      unitPrice: 700
    },
    {
      description: "March shopify subscription charges (remaining adjustment)",
      sacCode: "998314",
      quantity: 1,
      quantityUnit: "Month(s)",
      unitPrice: 853
    },
    {
      description: "April shopify subscription charges",
      sacCode: "998314",
      quantity: 1,
      quantityUnit: "Month(s)",
      unitPrice: 2353
    },
    {
      description: "Domain changes - diecutsmart.in for 2 year(s)",
      sacCode: "998314",
      quantity: 1,
      quantityUnit: "Subscription",
      unitPrice: 1498.00
    },
    {
      description: "Domain Handling Charges",
      sacCode: "998314",
      quantity: 1,
      quantityUnit: "Domain",
      unitPrice: 67.19
    }
  ],
  tax: {
    cgst: 9,
    sgst: 9
  },
  bankDetails: BankDetails.Primary
}
