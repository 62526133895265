import { IInvoiceModel } from "../../../InvoiceModel";
import { CompanyDetails } from "../../_shared/CompanyDetails";
import { BankDetails } from "../../_shared/BankDetails";
import { ClientDetails } from "../../_shared/ClientDetails";

export const ABIC1016: IInvoiceModel = {
  invoiceNumber: "ABIC-1016",
  invoiceDate: "4th Jun 2024",
  companyDetails: CompanyDetails.BangaloreOffice_IconLaurels,
  billingPeriod: {
    from: "1st May 2024",
    to: "31st May 2024"
  },
  billTo: ClientDetails.DiecutsMart,
  billingDetails: [
    {
      description: "Monthly Maintenance & Extra calling support",
      sacCode: "998314",
      quantity: 25,
      quantityUnit: "Hour(s)",
      unitPrice: 500
    },
    {
      description: "Domain mapping development cost and Template Issue Fixes",
      sacCode: "998314",
      quantity: 10,
      quantityUnit: "Hour(s)",
      unitPrice: 700
    },
    {
      description: "April Month eCommerce platform charges (including Transaction fees)",
      sacCode: "998314",
      quantity: 1,
      quantityUnit: "Month(s)",
      unitPrice: 5461.35
    },
    {
      description: "May Month eCommerce platform charges (including Transaction fees)",
      sacCode: "998314",
      quantity: 1,
      quantityUnit: "Month(s)",
      unitPrice: 19757.21
    }
  ],
  tax: {
    cgst: 9,
    sgst: 9
  },
  bankDetails: BankDetails.Primary
}
