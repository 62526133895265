import { IInvoiceModel } from "../../../InvoiceModel";
import { CompanyDetails } from "../../_shared/CompanyDetails";
import { BankDetails } from "../../_shared/BankDetails";
import { ClientDetails } from "../../_shared/ClientDetails";

export const ABIC1008: IInvoiceModel = {
  invoiceNumber: "ABIC-1008",
  invoiceDate: "20th Mar 2024",
  companyDetails: CompanyDetails.BangaloreOffice_IconLaurels,
  billingPeriod: {
    from: "6th Mar 2024",
    to: "18th Mar 2024"
  },
  billTo: ClientDetails.QuessCorpLimited,
  billingDetails: [
    {
      description: "Order to Cash (O2C) Training",
      sacCode: "999293",
      quantity: 11,
      quantityUnit: "Day(s)",
      unitPrice: 23000
    }
  ],
  tax: {
    cgst: 9,
    sgst: 9
  },
  bankDetails: BankDetails.Primary
}
