import { IBankDetailModel } from "../../InvoiceModel";

export class BankDetails {
  static Primary: IBankDetailModel = {
    bankName: "HDFC Bank",
    accountName: "Absquar Solutions",
    accountNumber: 50200088206595,
    ifscCode: "HDFC0003825"
  }
}
