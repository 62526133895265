import { ICompanyDetailModel } from "../../InvoiceModel";

export class CompanyDetails {
  static BangaloreOffice: ICompanyDetailModel = {
    companyName: "Absquar Solutions",
    addressLine1: "3rd Floor, 40/1B, #302, Sri Dakshayani Apartments",
    addressLine2: "Yemlur Main Road, Yemalur",
    city: "Bengaluru",
    state: "Karnataka",
    pinCode: 560037,
    contactNumber: "+91-8618795392",
    emailId: "billing@absquar.com",
    panNumber: "ACDFA3038P",
    gstInNumber: "29ACDFA3038P1Z2",
    website: "absquar.com"
  }
  static BangaloreOffice_IconLaurels: ICompanyDetailModel = {
    companyName: "Absquar Solutions",
    addressLine1: "#351, Icon Laurels, Hennagara Main Road",
    addressLine2: "Kachanayakanahalli",
    city: "Bengaluru",
    state: "Karnataka",
    pinCode: 560105,
    contactNumber: "+91-8618795392",
    emailId: "billing@absquar.com",
    panNumber: "ACDFA3038P",
    gstInNumber: "29ACDFA3038P1Z2",
    website: "absquar.com"
  }
}
