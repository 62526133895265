<script>
import { Options, Vue } from "vue-class-component";
import Navbar from "../static/components/Navbar.vue";
import Footer from "../static/components/Footer.vue";
import { IInvoiceModel } from "./InvoiceModel";
import { InvoiceService } from "./InvoiceService";
import { Filters } from "../../utils/Filters";
import { ToWords } from "to-words";

@Options({
  computed: {
    ToWords() {
      return ToWords
    },
    Filters() {
      return Filters
    },
    subTotal() {
      if(this.invoiceData == null) return 0;
      return this.invoiceData.billingDetails.reduce((sum, current) => sum + (current.unitPrice * current.quantity), 0);
    },
    sgstTax() {
      if(this.invoiceData == null || this.invoiceData.tax.sgst == null) return 0;
      return ((this.subTotal * this.invoiceData.tax.sgst) / 100);
    },
    cgstTax() {
      if(this.invoiceData == null || this.invoiceData.tax.cgst == null) return 0;
      return ((this.subTotal * this.invoiceData.tax.cgst) / 100);
    },
    igstTax() {
      if(this.invoiceData == null || this.invoiceData.tax.igst == null) return 0;
      return ((this.subTotal * this.invoiceData.tax.igst) / 100);
    },
    totalInclTax() {
      return this.subTotal + this.cgstTax + this.sgstTax + this.igstTax;
    }
  },
  props: {
    id: String
  },
  components: {
    Navbar,
    Footer
  },
  data() {
    return {
      invoiceData: IInvoiceModel | null
    }
  },
  methods: {
    telHref: (value) => {
      return `tel:${value}`;
    },
    mailToHref: (value) => {
      return `mailto:${value}`;
    },
    webLinkHref: (value) => {
      return `https://${value}/`;
    }
  }
})
export default class InvoicePdfView extends Vue {
  mounted() {
    if(this.id != "") {
      this.invoiceData = InvoiceService.GetMappedInvoice(this.id);
    }
  }
}
</script>

<template>
  <div>
    <div>
      <section v-if="invoiceData" class="section invoice pdf-view">
        <div class="container">
          <div class="row">
            <div class="col-sm-2">
              <a href="https://absquar.com/">
                <img class="invoice--logo-img" src="@/assets/images/logo-dark.png" alt="Absquar" width="80" />
              </a>
            </div>
            <div class="col-sm-6">
              <h2 class="invoice--company-name">{{ invoiceData.companyDetails.companyName }}</h2>
              <div class="invoice--company-details">
                <p class="invoice--company-details--address">{{ invoiceData.companyDetails.addressLine1 }},</p>
                <p class="invoice--company-details--address">{{ invoiceData.companyDetails.addressLine2 }}, {{ invoiceData.companyDetails.city }}, {{ invoiceData.companyDetails.state }}, {{ invoiceData.companyDetails.pinCode }}</p>
                <div class="invoice--company-details---contact">
                  <p>
                    <i class="fa-solid fa-phone"></i>
                    <a :href='telHref(invoiceData.companyDetails.contactNumber)'>{{ invoiceData.companyDetails.contactNumber }}</a>
                  </p>
                  <p>
                    <i class="fa-solid fa-envelope"></i>
                    <a :href="mailToHref(invoiceData.companyDetails.emailId)">{{ invoiceData.companyDetails.emailId }}</a>
                  </p>
                </div>
                <p class="invoice--company-details---website">
                  <i class="fa fa-globe"></i>
                  <a :href="webLinkHref(invoiceData.companyDetails.website)">www.{{ invoiceData.companyDetails.website }}</a>
                </p>
                <p v-if="invoiceData.companyDetails.panNumber" class="invoice--company-gst">
                  <span>PAN No.</span>
                  <span>{{ invoiceData.companyDetails.panNumber }}</span>
                </p>
                <p v-if="invoiceData.companyDetails.gstInNumber" class="invoice--company-gst">
                  <span>GSTIN No.</span>
                  <span>{{ invoiceData.companyDetails.gstInNumber }}</span>
                </p>
              </div>
            </div>
            <div class="col-sm-4 invoice--summary-header">
              <h2 class="invoice--summary-header--title">INVOICE</h2>
              <p class="invoice--summary-header--number">
                <i class="fa fa-hashtag" aria-hidden="true"></i>
                <span>{{ invoiceData.invoiceNumber }}</span>
              </p>
              <p class="invoice--summary-header--date">{{ invoiceData.invoiceDate }}</p>
            </div>
          </div>
          <div class="row invoice--bill-to">
            <div class="col-sm-6">
              <h2 class="invoice--bill-to--title">Bill To</h2>
              <div class="invoice--bill-to--details">
                <p class="invoice--bill-to--company-name">{{ invoiceData.billTo.companyName }}</p>
                <p class="invoice--bill-to--company-address">{{ invoiceData.billTo.addressLine1 }},</p>
                <p class="invoice--bill-to--company-address">{{ invoiceData.billTo.addressLine2 }}</p>
                <p class="invoice--bill-to--company-address">{{ invoiceData.billTo.city }}, {{ invoiceData.billTo.state }}, {{ invoiceData.billTo.pinCode }}</p>
                <div class="invoice--bill-to--company-contact">
                  <p v-if="invoiceData.billTo.contactNumber">
                    <i class="fa-solid fa-phone"></i>
                    <a :href="telHref(invoiceData.billTo.contactNumber)">{{ invoiceData.billTo.contactNumber }}</a>
                  </p>
                  <p v-if="invoiceData.billTo.emailId">
                    <i class="fa-solid fa-envelope"></i>
                    <a :href="mailToHref(invoiceData.billTo.emailId)">{{ invoiceData.billTo.emailId }}</a>
                  </p>
                </div>
                <p v-if="invoiceData.billTo.panNumber" class="invoice--bill-to--company-pan-gst">
                  <span>PAN No.</span>
                  <span>{{ invoiceData.billTo.panNumber }}</span>
                </p>
                <p v-if="invoiceData.billTo.gstInNumber" class="invoice--bill-to--company-pan-gst">
                  <span>GSTIN No.</span>
                  <span>{{ invoiceData.billTo.gstInNumber }}</span>
                </p>
              </div>
            </div>
            <div class="col-sm-6">
              <h2 class="invoice--bill-to--period-title">Billing Period</h2>
              <p class="invoice--bill-to--period-date">{{ invoiceData.billingPeriod.from }} to {{ invoiceData.billingPeriod.to }}</p>
            </div>
          </div>
          <div class="row invoice--details">
            <div class="col-sm-12">
              <h2 class="invoice--details--title">Billing Details</h2>
            </div>
          </div>
          <div class="invoice--details--table">
            <div class="row invoice--details">
              <div class="col-sm-4 invoice--details--th">Description</div>
              <div class="col-sm-2 invoice--details--th">SAC Code</div>
              <div class="col-sm-2 invoice--details--th">Qty</div>
              <div class="col-sm-2 invoice--details--th">Unit Price</div>
              <div class="col-sm-2 invoice--details--th">Total</div>
            </div>
            <div v-for="(detail, index) in invoiceData.billingDetails"
                 :key="index" class="row invoice--details">
              <div class="col-sm-4 invoice--details--tb">
                {{ detail.description }}
              </div>
              <div class="col-sm-2 invoice--details--tb">{{ detail.sacCode }}</div>
              <div class="col-sm-2 invoice--details--tb">{{ detail.quantity }} {{ detail.quantityUnit }}</div>
              <div class="col-sm-2 invoice--details--tb">{{ Filters.Currency(detail.unitPrice) }}</div>
              <div class="col-sm-2 invoice--details--tb">{{ Filters.Currency(detail.unitPrice * detail.quantity) }}</div>
            </div>
            <div class="row invoice--details">
              <div class="col-sm-10 invoice--details--tb invoice--details--tb--bottom label">Sub Total</div>
              <div class="col-sm-2 invoice--details--tb invoice--details--tb--bottom">{{ Filters.Currency(this.subTotal) }}</div>
            </div>
            <div v-if="invoiceData.tax.cgst" class="row invoice--details">
              <div class="col-sm-10 invoice--details--tb invoice--details--tb--bottom label">
                CGST
                <span>({{ invoiceData.tax.cgst }}%)</span>
              </div>
              <div class="col-sm-2 invoice--details--tb invoice--details--tb--bottom">{{ Filters.Currency(this.cgstTax) }}</div>
            </div>
            <div v-if="invoiceData.tax.sgst" class="row invoice--details">
              <div class="col-sm-10 invoice--details--tb invoice--details--tb--bottom label">
                SGST
                <span>({{ invoiceData.tax.sgst }}%)</span>
              </div>
              <div class="col-sm-2 invoice--details--tb invoice--details--tb--bottom">{{ Filters.Currency(this.sgstTax) }}</div>
            </div>
            <div v-if="invoiceData.tax.igst" class="row invoice--details">
              <div class="col-sm-10 invoice--details--tb invoice--details--tb--bottom label">
                IGST
                <span>({{ invoiceData.tax.igst }}%)</span>
              </div>
              <div class="col-sm-2 invoice--details--tb invoice--details--tb--bottom">{{ Filters.Currency(this.igstTax) }}</div>
            </div>
            <div class="row invoice--details">
              <div class="col-sm-10 invoice--details--tb invoice--details--tb--bottom label total">
                Total Charges
              </div>
              <div class="col-sm-2 invoice--details--tb invoice--details--tb--bottom total">{{ Filters.Currency(this.totalInclTax) }}</div>
            </div>
            <div class="row invoice--details">
              <div class="col-sm-12 invoice--details--tb invoice--details--tb--bottom label in-words">
                {{ new ToWords().convert(this.totalInclTax.toFixed(2)) }}
              </div>
            </div>
          </div>
          <div v-if="invoiceData.bankDetails" class="row invoice--details invoice--details--bank-details">
            <div class="col-sm-12">
              <h2 class="invoice--details--title">Bank Details</h2>
            </div>
          </div>
          <div v-if="invoiceData.bankDetails" class="invoice--details--table">
            <div class="row invoice--details">
              <div class="col-sm-3 invoice--details--th">Bank Name</div>
              <div class="col-sm-3 invoice--details--th">Account Name</div>
              <div class="col-sm-3 invoice--details--th">Account Number</div>
              <div class="col-sm-3 invoice--details--th">IFSC Code</div>
            </div>
            <div class="row invoice--details">
              <div class="col-sm-3 invoice--details--tb">{{ invoiceData.bankDetails.bankName }}</div>
              <div class="col-sm-3 invoice--details--tb">{{ invoiceData.bankDetails.accountName }}</div>
              <div class="col-sm-3 invoice--details--tb">{{ invoiceData.bankDetails.accountNumber }}</div>
              <div class="col-sm-3 invoice--details--tb">{{ invoiceData.bankDetails.ifscCode }}</div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-4"></div>
            <div class="col-sm-8 invoice--signature-seal">
              <img class="seal-img" src="@/assets/images/absquar/seal_with_sign.png" alt="Absquar-Seal-Sign" />
<!--              <img class="seal-img" src="@/assets/images/absquar/seal.png" alt="Absquar-Seal" />
              <br/>
              <img class="sign-img" src="@/assets/images/absquar/sign-boban.png" alt="Absquar-Seal" />-->
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
